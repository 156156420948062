import React,{ FC, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { addFolder, fetchFolders, fetchHyperProjects, fetchProjects, getStatusSummary, getTokenFromShareableLink, setActiveFolder, setFolderLoader, setFolders, setFoldersPageData, setProjectLoader, setProjects, setSelectedFolders, setSelectedProject, setPublicUser, setPublicToken } from '../../../store/actions';
import { AuthState, Folder, FolderState, HyperProjectFoldersState } from '../../../store/types';
import { openToast } from '../../../Toasts';
import { Folders, Banners } from '../../DashboardSection/Content';
import Img from '../../ImageLoader/ImageLoader';
import EmptyPlaceHolder from '../../shared/EmptyPlaceHolder';
import PromptPopUp from '../../shared/PromptPopUp';
import NoResultsFound from '../../../assets/img/noresultsfound.png';
import EmptyFolder from '../../../assets/img/emptyfolder.png';
import { enableEdit } from '../../../utilities/contants';
import { Loading3QuartersOutlined } from '@ant-design/icons';

const PublicCreative: FC = () => {
    const state = useSelector((state: { folders: FolderState }) => (state.folders));
    const { userData } = useSelector((state: { authentication: AuthState }) => (state.authentication));
    const publicLink = useSelector((state: { publicLink: any }) => (state.publicLink));
    const { projectsForReadyCheck } = useSelector((state: { hyperProject: HyperProjectFoldersState }) => (state.hyperProject))
    const dispatch = useDispatch();
    const { folders, activeFolderId, banners, folderLoading, projectLoading, filter} = state;
    const folderFilter: Function = useCallback((id: number) => folders.filter(folder => folder.parent === id && (folder.type === 'project' || folder.type === 'hpproject')), [folders]);
    let filteredFolders: Folder[] = folderFilter(activeFolderId);
    const getActiveFolder = useCallback((id: number, type: string) => (
        folders.find(folder => folder.id === id && (folder.type === type || folder.type === "hpproject")) || null
    ), [folders])
    const queryParams: any = useParams();
    const [isExpired, setIsExpired] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [isVleRequired, setIsVleRequired] = useState(false);
    const [promptData, setPromptData] = useState({title:"",text:"",placeholder:"",submitBtnText:"",inputText:"",key:""});
    const [loading, setLoader] = useState(true);
    const [type, setType] = useState('');
    const [shareableType, setShareableType] = useState('');
    const history = useHistory();
    const [drawerVisible,setDrawerVisible]=useState('');
    const isEditEnabled:boolean = enableEdit.some((val:string)=>(userData?.emailId.includes(val)));
    const activeFolderFind: Function = useCallback((id: number) => folders.find(folder => folder.id === id && (folder.type === type || folder.type === "hpproject")), [folders]);
    let activeFolder: Folder = activeFolderFind(activeFolderId);
    const [isLoading, setIsLoading] = useState(true);

    const setContent = (params: any) => {
        const { public_access_token, entity_id, entity_type } = params;
        setShowPrompt(false);
        setShareableType(params['shareable_type'])
        dispatch(setPublicToken(public_access_token));
        if (params['shareable_type'] === "design") {
            dispatch(setPublicUser({
                name: params['created_by'],
                profilePicture: params['creator_profile_picture'],
                shareableType: params['shareable_type'],
                entity_id,
                entity_type,
            }));
            history.push(`/cocreate/public${entity_type === 'project' ? '' : '/creative-automation'}/banner-detail/?banner=${[entity_id]}`);

        } else {
            dispatch(setFolderLoader(true))
            dispatch(fetchFolders({ type: entity_type, parent: [entity_id] }, (folderRes: any, err: boolean) => {
                dispatch(setPublicUser({
                    name: params['created_by'],
                    profilePicture: params['creator_profile_picture'],
                    folderName: folderRes['parent']?.name || '',
                    shareableType: params['shareable_type'],
                    entity_id,
                    entity_type,
                }))
                dispatch(setFolderLoader(false))
                if (!err) {
                    dispatch(addFolder(folderRes.results));
                }
            }));
            dispatch(setActiveFolder(entity_id));
        }
    }

    const fetchProjectData = useCallback((params: any, setLoader?: Function) => {
        const { project_type, password } = params;
        setIsExpired(false);
        dispatch(getTokenFromShareableLink(params, (res: any, err: boolean) => {
            if (!err) {
                const data = {
                    title:"Enter the required details",
                    text:"Please enter the password to open this link",
                    placeholder:"Enter password",
                    submitBtnText:"Get access",
                    inputText:"",
                    key:"password",
                }
                setPromptData(data);

                if (res.data['shareable_link_exist']) {
                    setIsExpired(true)
                } else if (res.data['password_required'] && res.data['vleId_required'] && !publicLink.token) {
                    setIsVleRequired(true);
                    setShowPrompt(true);
                }  else if (res.data['password_required'] && !publicLink.token) {
                    setShowPrompt(true);
                }  else if (res.data['vleId_required'] && !publicLink.token) {
                    const data = {
                            title:"Enter the required details",
                            text:"Please enter the VLE code",
                            placeholder:"Enter VLE",
                            submitBtnText:"Get access",
                            inputText:"",
                            key:"vle_code",
                        }

                    setPromptData(data);
                    setShowPrompt(true);

                } else {
                    setContent(res['data'])
                }
            } else {
                openToast('error', res.data.message || (password ? "Unable to Access" : "Unable to get project"))
            }
            setLoader(false);
        }))
    }, [showPrompt])

    const getStatusSummaryData = (responseData:any) => {
        let ids = responseData.reduce((acc: number[], val: any) => [...acc, val.id], []);
        dispatch(getStatusSummary({ ids, entity_type: type }, (summaryRes: any, summaryErr: boolean) => {
            dispatch(setProjectLoader(false));
            if(!summaryErr) {
                let { data: { results } } = summaryRes;
                results.forEach((d: any) => {
                    let found: any = responseData.findIndex((obj: any) => obj.id.toString() === d.entity_id);
                    if(found !== -1) {
                        responseData[found]["status"] = d.status;
                        responseData[found]["entity_id"] = d.entity_id;
                    }
                })
            }
            dispatch(setProjects(responseData));
        }));
    }

    useEffect(() => {
        if(publicLink && publicLink.token) {
            const { entity_id, entity_type, shareableType, name, profilePicture } = publicLink;
            setType(entity_type);
            setContent({
                entity_id,
                entity_type,
                shareable_type: shareableType,
                public_access_token: publicLink.token,
                creator_profile_picture: profilePicture,
                created_by: name,
            })
        }
        else if (queryParams['projectLinkKey'] && queryParams['projectType']) {
            fetchProjectData({ hash_code: queryParams['projectLinkKey'], project_type: queryParams['projectType'] === 'creative-automation' ? 'hp-project' : queryParams['projectType'] }, setLoader);
            setType(queryParams['projectType'] === 'creative-automation' ? 'hpproject' : queryParams['projectType']);
        }
        return () => {
            dispatch(setFolders([]));
            dispatch(setSelectedProject([]));
            dispatch(setSelectedFolders([]));
            dispatch(setProjects([]));
            dispatch(setActiveFolder(-1));
            dispatch(setFoldersPageData(null));
        }
    }, [])

    useEffect(() => {
        if (activeFolderId > -1) {
            if (type === "project") {
                dispatch(setProjectLoader(true));
                setIsLoading(false);
                dispatch(fetchProjects({ ...filter || {}, page: 1, folder_id: activeFolderId ? activeFolderId : undefined, page_size: 20, order_by: '-created_at' }, (res: any, err: boolean) => {
                    if (!err) {
                        dispatch(setFoldersPageData({
                            count: res.count,
                            current_page: res.current_page,
                            page_size: res.page_size,
                            pages: res.pages
                        }))
                        res = res.data;
                        getStatusSummaryData(res);
                    } else {
                        dispatch(setProjectLoader(false))
                    }
                }))
            }
            else if (type === "hpproject") {
                dispatch(setProjectLoader(true));
                setIsLoading(false);
                dispatch(fetchHyperProjects({ ...filter || {}, page: 1, folder: activeFolderId ? activeFolderId : undefined, page_size: 20, order_by: '-created_at' }, (res: any, err: boolean) => {
                    if (!err) {
                        getStatusSummaryData(res);
                    } else {
                        dispatch(setProjectLoader(false))
                    }
                }))
            }
        }
        return () => {
            dispatch(setSelectedProject([]))
            dispatch(setProjects([]));
            dispatch(setFoldersPageData(null))
            dispatch(setSelectedFolders([]))
        }
    }, [activeFolderId, filter])
    
    return (
        <div className="dashboard-wrap">
            <div className="flex-column" style={{width: '100%'}}>
                <div className="flex h-100">
                    <div className="dashboard-content" id="content" style={{marginBottom: '60px'}}>
                        {!filter && shareableType==='folder' && <Folders
                            folders={filteredFolders} activeFolder={activeFolder} componentCaller="folder" 
                            drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible} isPublicLink={true}
                            isEditEnabled={isEditEnabled} canCreate={false} type={type}
                        />}
                        <Banners type={type} title="Banners" showTitle={banners.length > 0} isPublicLink={true} isEditEnabled={isEditEnabled}
                            canUpload={false} loading={projectLoading} projectsForReadyCheck={projectsForReadyCheck} getActiveFolder={getActiveFolder}
                            componentCaller="project" drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible} 
                        />
                        {isLoading ? <div className="loader" style={{width: '100%', display: 'flex', justifyContent: 'center', fontSize: '2.4rem', margin: 'auto'}}>
                            <Loading3QuartersOutlined spin/>
                        </div> : 
                        ((filteredFolders.length > 0 && banners.length === 0 && !folderLoading && !projectLoading && filter && filter['search']) || (filteredFolders.length === 0 && banners.length === 0 && !folderLoading && !projectLoading)) && <EmptyPlaceHolder
                            icon={<Img src={filteredFolders.length > 0 ? NoResultsFound : EmptyFolder} />}
                            text={filteredFolders.length > 0 ?
                                `Oops! Nothing found in folder ${activeFolderId === 0 ? 'Main Folder' : getActiveFolder(activeFolderId, type)?.name}`
                                : "This folder is empty"
                            }
                            cutomAction={null}
                            isSubmitBtn={false}
                        />}
                        {showPrompt && <PromptPopUp
                            isUpdate={false}
                            isVleRequired={isVleRequired}
                            handleClose={() => setShowPrompt(false)}
                            onSubmit={(value:{value:string,vle_code:string}, setLoader:Function) => {
                                if (queryParams['projectLinkKey']) {
                                    setLoader(true);
                                    let paramData = { hash_code: queryParams['projectLinkKey'], project_type: queryParams['projectType'] === 'creative-automation' ? 'hp-project' : queryParams['projectType'],vle_code:value.vle_code };
                                    paramData[promptData.key] = value.value;
                                    fetchProjectData(paramData, setLoader);
                                } else {
                                    openToast('warn', 'Seems like the project link is not valid!');
                                }
                            }}
                            title={promptData.title}
                            text={promptData.text}
                            placeholder={promptData.placeholder}
                            submitBtnText={promptData.submitBtnText}
                            inputText={promptData.inputText}
                        />}
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default PublicCreative;