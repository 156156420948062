import { all } from 'redux-saga/effects';
import { getImagesWatcher } from './imagesTraySaga';
import { getElementsWatcher, getCategoriesWatcher } from './elementsTraySaga';
import { foldersWatcher } from './projectFolders';
import { libraryWatcher } from './library';
import { workflowWatcher } from './workflow';
import { approvalWatcher } from './approval';
import { AuthenticationWatcher } from './authentication';
import { rolesWatcher } from './roles';
import { createAiquireWatcher } from './createAiquireSaga';
import { hyperPersonalizeWatcher } from './hyperPersonalize';
import { hyperProjectFolderWatcher } from './hyperProjectFolders';
import { getTemplatesWatcher } from './templatesTraySaga';
import { exportMultipleWatcher, exportWatcher } from './export';

export function* rootSaga() {
    yield all([
      getImagesWatcher(),
      getElementsWatcher(),
      getCategoriesWatcher(),
      foldersWatcher(),
      libraryWatcher(),
      workflowWatcher(),
      approvalWatcher(),
      AuthenticationWatcher(),
      rolesWatcher(),
      createAiquireWatcher(),
      hyperPersonalizeWatcher(),
      hyperProjectFolderWatcher(),
      getTemplatesWatcher(),
      exportWatcher(),
      exportMultipleWatcher()
    ]);
}
